/* eslint-disable no-console, no-undef, camelcase */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { connect } from 'react-redux';
import { OauthSender } from 'react-oauth-flow';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  DeviceContainer, StyledBody, LogoWrapper, ImageContainer, CustomLabel, CustomCheckbox, Slider, LinkedStatus, ButtonContainer,
  StyledTitle, CloseIcon, StyledModal
} from './styles';
import { FITBIT_URI, FITBIT_CLIENT_ID, REDIRECT_URI, STRAVA_URI, STRAVA_CLIENT_ID, WITHINGS_URI, WITHINGS_CLIENT_ID, STRAVA_AUTHORIZATION_URI } from '../../utils/constants';
import {
  fitbitIntegration, stravaIntegration, withingsIntegration,
  revokeDevice, updateDevices, getRequestToken, connectDevice
} from '../../redux/actions';
import { withTranslation } from 'react-i18next';

const DEVICE_DATA = {
  FITBIT_URI: FITBIT_URI,
  STRAVA_URI: STRAVA_URI,
  FITBIT_CLIENT_ID: FITBIT_CLIENT_ID,
  STRAVA_CLIENT_ID: STRAVA_CLIENT_ID,
  WITHINGS_CLIENT_ID: WITHINGS_CLIENT_ID,
  WITHINGS_URI: WITHINGS_URI
};

class ConnectDeviceModal extends Component {

  componentDidMount() {
    if (this.props.location.search) {
      const data = qs.parse(this.props.location.search);
      const oauthTokenSecret = localStorage.getItem('TOKEN_SECRET');
      localStorage.removeItem('TOKEN_SECRET');
      if (this.props.location.search.includes('oauth_token') && oauthTokenSecret) {

        const deviceInfo = {
          "device": "garmin",
          "action": "connect",
          "oauth_token": data.oauth_token,
          "oauth_verifier": data.oauth_verifier,
          "oauth_token_secret": oauthTokenSecret
        };
        this.props.connectDevice(deviceInfo);
      } else if (data.state) {
        let name = '';
        if (data.state === 'strava') {
          name = 'strava'
        }
        else {
          name = JSON.parse(data?.state)?.name;
        }
        const device = this.props.devices.filter((device) => device.name === name);
        if (!_.isEmpty(device) && !device[0].is_connected) {
          if (name === 'fitbit' && localStorage.getItem('fitbitStatus') == 'true') {
            this.props.fitbitIntegration(data.code);
            localStorage.setItem('fitbitStatus', 'false');
          } else if (name === 'strava') {
            const deviceInfo = {
              "device": "strava",
              "action": "connect",
              "oauth_token": data.code,
              "platform": 'web'
            };
            if (!_.isUndefined(data) && data && data.scope && data.scope.split(',').length > 1 && data && data.scope && data.scope.split(',')[1].includes('read_all')) {
              this.props.connectDevice(deviceInfo);
            }
            else {
              toast.info('Please select required permissions', { autoClose: 30000 });
              window.location.href = `${STRAVA_AUTHORIZATION_URI}?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&approval_prompt=force&scope=read,activity:read_all&state=strava`;
            }
          } else if (name === 'withings') {
            this.props.withingsIntegration(data.code);
          }
        }
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.devicesDisconnectedList !== this.props.devicesDisconnectedList) {
      this.props.closeModal();
    }
  }

  onChange = (name, url, isConnected) => {
    if (name == 'healthapp' || name == 'googlefit') {
      if (name == 'healthapp') {
        toast.info('Please Authorize Apple Health Kit in Woliba iOS App');
      }
      else {
        toast.info('Please Authorize Google Fit in Woliba Android App');
      }
    }
    else {
      if (isConnected) {
        this.props.updateDevices(name, false);
        this.props.revokeDevice(name);
      } else {
        this.props.updateDevices(name, true);
        switch (name) {
        case 'fitbit': {
          localStorage.setItem('fitbitStatus', 'true');
          window.location.href = url;
          break;
        }
        case 'strava': {
          window.location.href = `${STRAVA_AUTHORIZATION_URI}?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&approval_prompt=force&scope=read,activity:read_all&state=strava`;
          break;
        }
        case 'withings': {
          window.location.href = url;
          break;
        }
        case 'garmin': {
          this.props.fetchRequestToken('garmin');
          break;
        }
        default: break;
        }
      }
    }
  };

  render() {
    const { showModal, closeModal, devices, t } = this.props;
    return (
      <StyledModal show={showModal} onHide={() => closeModal()}>
        <Modal.Header >
          <StyledTitle>{t("CONNECT DEVICES")}</StyledTitle>
          <CloseIcon className="closeIcon" style={{bottom:"0px"}}>
            <img src="/public/images/NewDashboardV2/crossIconNew.png" width="20px" alt="" style={{ "cursor": "pointer" }} onClick={() => closeModal()} />
          </CloseIcon>
        </Modal.Header>
        <StyledBody>
          {
            devices && devices.map((device, index) => {
              const Source = device.name.replace(/ /g, '-');
              const BASE_URI = DEVICE_DATA[`${device.name.toUpperCase()}_URI`];
              const BASE_CLIENT_ID = DEVICE_DATA[`${device.name.toUpperCase()}_CLIENT_ID`];
              return (
                <DeviceContainer key={index}>
                  <div>
                    <ImageContainer>
                      <img src={`/public/images/Logo/${Source}.png`} />
                    </ImageContainer>
                    <LogoWrapper>
                      <div>{device.display_name}</div>
                      <LinkedStatus isLinked={device.is_connected}>
                        {device.is_connected ? <span><i className="fas fa-wifi" />&nbsp;<span>{t("Linked")}</span></span> : t('Not Linked')}
                      </LinkedStatus>
                    </LogoWrapper>
                  </div>
                  <div>
                    {
                      device.name === 'fitbit' || device.name === 'strava' || device.name === 'withings' ?
                        <OauthSender
                          authorizeUrl={BASE_URI}
                          clientId={BASE_CLIENT_ID}
                          redirectUri={REDIRECT_URI}
                          state={{ name: device.name }}
                          render={({ url }) =>
                            <CustomLabel className="switch">
                              <CustomCheckbox
                                type="checkbox"
                                checked={device.is_connected}
                                onChange={() => this.onChange(device.name, url, device.is_connected)}
                              />
                              <Slider className="slider round" />
                            </CustomLabel>
                          }
                        /> :
                        <CustomLabel className="switch">
                          <CustomCheckbox
                            type="checkbox"
                            checked={device.is_connected}
                            onChange={() => this.onChange(device.name, '', device.is_connected)}
                          />
                          <Slider className="slider round" />
                        </CustomLabel>
                    }
                  </div>
                </DeviceContainer>
              )
            })
          }
          <ButtonContainer />
        </StyledBody>
      </StyledModal>
    )
  }
}

ConnectDeviceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  fitbitIntegration: PropTypes.func.isRequired,
  stravaIntegration: PropTypes.func.isRequired,
  withingsIntegration: PropTypes.func.isRequired,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func.isRequired,
  updateDevices: PropTypes.func.isRequired,
  fetchRequestToken: PropTypes.func.isRequired,
  connectDevice: PropTypes.func.isRequired,
  devicesDisconnectedList: PropTypes.array,
  t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  fitbitIntegration: (token) => dispatch(fitbitIntegration(token)),
  stravaIntegration: (token) => dispatch(stravaIntegration(token)),
  withingsIntegration: (authToken) => dispatch(withingsIntegration(authToken)),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  updateDevices: (name, value) => dispatch(updateDevices(name, value)),
  fetchRequestToken: (name) => dispatch(getRequestToken(name)),
  connectDevice: (deviceInfo) => dispatch(connectDevice(deviceInfo))
});

export default connect(null, mapDispatchToProps)(withRouter((withTranslation()(ConnectDeviceModal))));
